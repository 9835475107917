<template>
  <div id="content">
    <span v-if="responseStatus">api: {{ responseStatus }}</span>
    <span v-if="status">{{ status }}</span>
  </div>
</template>

<script>
import LayoutLess from '../../layouts/LayoutLess'
import config from '../../config'
import Vue from 'vue'

export default {
  name: 'Status',
  data: () => ({
    status: null,
    responseStatus: null
  }),
  created () {
    this.$emit('update:layout', LayoutLess)

    document.title = config.title + ' - Status'

    this.getStatus()
  },
  methods: {
    getStatus () {
      Vue.prototype.$http
        .get(config.apiUrl + '/status')
        .then(response => {
          if (response.status === 200) {
            this.status = response.data
            this.responseStatus = 'OK'
          }
        })
        .catch(e => {
          this.responseStatus = 'KO'
        })
    }
  }
}
</script>
